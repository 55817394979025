<template>
	<v-dialog :value="true" min-width="300" max-width="300" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card>
			<v-card-title>Pick a Date</v-card-title>

			<v-card-text>
				<v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field v-model="day" type="date" label="Day" prepend-icon="fad fa-calendar" v-bind="attrs" v-on="on" hide-details dense outlined></v-text-field>
					</template>
					<v-date-picker v-model="day" no-title scrollable color="primary" @input="menu = false"></v-date-picker>
				</v-menu>
			</v-card-text>

			<v-card-actions>
				<v-btn @click="modal.trigger('close')" class="ml-auto">Close</v-btn>
				<v-btn @click="modal.trigger('save close', day)" color="primary">Pick</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import moment from "moment";

	export default {
		name: "getDate",

		props: {
			modal: {
				type: Object,
				required: true,
			},
			value: {
				type: String
			},
		},

		data() {
			let day = moment(this.value);
			if (!day.isValid()) {
				day = moment();
			}

			return {
				day: day.format("YYYY-MM-DD"),
				menu: false,
			};
		},
	};
</script>